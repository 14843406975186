import { Link } from "gatsby";
import * as React from "react"
const ProjectCard = ({ projectContent, index }) => {

    const tools = projectContent.Tools.split(",");
    const CONTENT_URL = "https://content.shaneducksbury.com"

    console.log(projectContent)

    const projectLinkUrl = '/projects/' + projectContent.Title.toLowerCase().replace(" ", "-")

    return(
        <>
        <div className="project-card-wrapper">
            <div className="project-image card-image-horizontal">
                <img src={CONTENT_URL + projectContent.ProjectScreenshot.url} alt="" />
            </div>
            <div className="card project-card">
                <div className="card-content">
                    <h3>{projectContent.Title}</h3>
                    <ul className="project-tool-list">
                        {tools.map((tool, index) => {
                            return(<li className="tool-text" key={index}>{tool}</li>)
                        })}
                    </ul>
                    <p>{projectContent.Description}</p>
                    <Link to={projectContent.projectPath}>Read More →</Link>
                    <div className="project-buttons">
                        {projectContent.GithubLink ? 
                            <a href={projectContent.GithubLink} target="_blank" className="demo-button">GitHub Repo</a>
                        : null}
                        {projectContent.DemoLink ? 
                            <a href={projectContent.DemoLink} target="_blank" className="demo-button">Live Demo</a>
                        : null }
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}
export default ProjectCard