import React, { useEffect } from "react"
import ProjectCard from "../components/projectCard"

const Projects = ({ allProjects }) => {

    useEffect(() => {
        sortProjectsByDate();
    },[allProjects])
        

    const sortProjectsByDate = () => {
        if(allProjects){
            const sortedArray = allProjects.sort(
                (objA, objB) => {
                    const objADate = new Date(objA.DateCreated);
                    const objBDate = new Date(objB.DateCreated);
                    return Number(objBDate) - Number(objADate);
                }
            )
        }
    }

    return(
        <>
        <section className="section-wrapper projects-wrapper">
            <h2>A Few Of My Projects</h2>
            {allProjects ? sortProjectsByDate() : null}
            {allProjects ? allProjects.map((content, index) => {
                return(<ProjectCard projectContent={content} index={index} key={content.id}/>)
            }) : null}
        </section>
    </>
    )
}
export default Projects